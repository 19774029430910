import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { dashboardLayoutRoutes, authLayoutRoutes, protectedRoutes } from "./index";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import Page403 from "../pages/auth/Page403";


const ChildRoutes = (Layout, routes) => {
    const { user, isAuthenticated } = useAuth0();
    return routes.map(({ component: Component, guard, children, path, authProtected, permission }, index) => {
        const Guard = guard || React.Fragment;

        return children ? (
            children.map((element, index) => {
                const Guard = element.guard || React.Fragment;

                return (
                    <Route key={index} path={element.path} exact render={(props) => (
                        <Guard>
                            <Layout>
                                {((isAuthenticated && (!permission.length || ((permission.filter(i => user['https://demo.myportal-app.com/permissions'].indexOf(i) > -1)).length > 0))) ? <element.component {...props} /> : <Page403 />)}
                            </Layout>
                        </Guard>
                    )}
                    />
                );
            })
        ) : Component ? (
            <Route key={index} path={path} exact render={(props) => (
                <Guard>
                    <Layout>
                        {((isAuthenticated && (!permission.length || ((permission.filter(i => user['https://demo.myportal-app.com/permissions'].indexOf(i) > -1)).length > 0))) ? <Component {...props} /> : <Page403 />)}
                    </Layout>
                </Guard>
            )}
            />
        ) : null;
    });
}

const Routes = () => {
    const { user, isAuthenticated } = useAuth0();
    const roles = user?.['https://demo.myportal-app.com/roles'] || [];
    const isAdmin = roles.includes('Admin');
    
    return (
        <Router>
            <Switch>
    
            <Route exact path="/">
                        {isAuthenticated ? (
                            isAdmin ? <Redirect to="/home" /> :
                            !isAdmin ? <Redirect to="/home" /> :
                            <Redirect to="/unauthorized" />
                        ) : <Page403 />}
                    </Route>
                {ChildRoutes(DashboardLayout, dashboardLayoutRoutes)} {ChildRoutes(DashboardLayout, protectedRoutes)} {ChildRoutes(AuthLayout, authLayoutRoutes)}
                <Route render={() => (
                    <AuthLayout>
                        <Page404 />
                    </AuthLayout>
                )
                } />
            </Switch>
        </Router>
    );
}

export default Routes;